import React, { useContext } from 'react';
import { ValidateCodeRequestResponse } from 'api/authenticationApi';

export interface Store {
  state?: ValidateCodeRequestResponse | undefined;
  setState?: any;
}

const store: Store = {
  state: undefined,
  setState: () => {},
};

const AuthContext = React.createContext(store);
AuthContext.displayName = 'Merchant Auth Context';
export const useAuthContext = () => useContext(AuthContext);
export default AuthContext;
