import React, { Suspense, lazy } from 'react';
import { ErrorBoundary } from './components/Error';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import AuthProvider from 'store/authContextProvider/AuthProvider';

const Header = lazy(() => import('./components/Header'));

const Authentication = lazy(() => import('./components/authentication'));
const Register = lazy(() => import('./components/register'));
const Dashboard = lazy(() => import('./components/dashboard/DashboardLayout'));

const App = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <AuthProvider>
            <div className="animate__animated animate__fadeIn td-200">
              <Header />
              <Routes>
                <Route path="/login" element={<Authentication />} />
                <Route path="/register" element={<Register />} />
                <Route path="/" element={<Dashboard />} />
                <Route path="/*" element={<Dashboard />} />
              </Routes>
            </div>
          </AuthProvider>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
