import Bugsnag from '@bugsnag/js';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from './authContext';
import { USER_AUTH } from '../../Constant';
import { ValidateCodeRequestResponse } from 'api/authenticationApi';

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const store = localStorage.getItem(USER_AUTH);
  const [state, setState] = useState<ValidateCodeRequestResponse | undefined>(
    JSON?.parse(store ?? '{}')
  );
  const location = useLocation();

  if (state?.data?.user?.active) {
    Bugsnag.setUser(
      state?.data?.user?.username ?? '',
      state?.data?.user?.email,
      state?.data?.user?.fullName
    );
  }

  useEffect(() => {
    if (store) {
      const user: ValidateCodeRequestResponse = JSON.parse(store);
      setState(user);
    } else {
      setState(undefined);
    }
    // eslint-disable-next-line
  }, [store, location?.pathname]);

  useEffect(() => {
    if (state! && state?.data?.user?.active) {
      // const currentPath = window.location.pathname;
      // if (!allowedPaths.includes(currentPath)) {
      //   navigate('/');
      // }
    } else {
      const { pathname, search } = location;
      if (pathname !== '/login' && pathname !== '/register') {
        navigate('/login', {
          state: {
            from: `${pathname}${search}`,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [state, navigate]);

  return (
    <AuthContext.Provider value={{ state, setState }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
