export interface MenuProps {
  name: string;
  path: string;
}

export const menu = [
  {
    name: 'Balances',
    path: '/balances',
  } as MenuProps,
  {
    name: 'Transactions',
    path: '/transactions?page=1',
  } as MenuProps,
  {
    name: 'Settings',
    path: '/settings',
  } as MenuProps,
];

export const balancesTab = [
  {
    label: 'Collection',
    value: 'collection',
  },
  {
    label: 'Payout',
    value: 'payout',
  },
];

export const settingsTab = [
  {
    label: 'Team',
    value: 'team',
  },
  {
    label: 'Security',
    value: 'security',
  },
  {
    label: 'Api & Webhooks',
    value: 'api-webhooks',
  },
];

export const allowedPaths = [
  '/',
  '/transactions',
  '/transactions/',
  '/transaction',
];

export const PER_PAGE = 20;

export const AUTH_STORAGE_KEY = 'merchant';

export const txnStatusOptions = [
  { label: 'Show all', value: '' },
  { label: 'Success', value: 'completed' },
  { label: 'Failed', value: 'failed' },
  { label: 'Pending', value: 'processing' },
];
export const txnCurrencyOptions = [
  { label: 'Show all', value: '' },
  { label: 'NGN', value: 'NGN' },
  { label: 'USD', value: 'USD' },
];
export const txnTypeOptions = [
  { label: 'Show all', value: '' },
  { label: 'Onramp', value: 'onramp' },
  { label: 'Offramp', value: 'offramp' },
  { label: 'Voucher', value: 'voucher' },
  { label: 'Payout', value: 'payout' },
  { label: 'Withdrawal', value: 'withdrawal' },
  { label: 'Transfer', value: 'transfer' },
];

export const balanceAccountOptions = [
  { label: 'Collection', value: 'collection' },
  { label: 'Payout', value: 'payout' },
];
export const MERCHANT = 'merchant';

export const USER_AUTH = 'userAuth';

export const COMPLETED = 'completed';

export const PROCESSING = 'processing';

export const SUCCESS = 'Success';

export const PENDING = 'pending';

export const FAILED = 'Failed';

//======== Balances ===========

export const CollectionCurrencies = ['USD', 'NGN', 'BTC', 'ETH'];
